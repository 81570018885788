@import "./stylekit";

.common-box {
  display: flex;
  flex-direction: column;
  height: 96px;
  margin: 5px;
  border-radius: 12px;
}

.common-box-small {
  width: 150px;
}

.common-box-medium {
  width: 180px;
}

.common-box-big {
  width: 370px;
}

.info-box {
  padding: 18px 10px;
  justify-content: center;
  align-items: center;
  position: relative;

  .info-box-title-container {
    flex: 1;
    text-align: center;

    .info-box-title {
      font-size: 11px;
    }
  }

  .info-box-content-container {
    display: flex;
    flex: 3;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;

    .info-box-content {
      display: flex;
      align-items: center;
    }
  }

  .info-box-small-text {
    font-size: 16px;
  }

  .info-box-big-text {
    font-size: 22px;
  }

  .info-box-small-icon {
    font-size: 16px;
  }

  .info-box-big-icon {
    font-size: 26px;
  }

  .info-box-footer {
    text-align: center;
    opacity: 0.5;
    font-size: 10px;
  }
}

.danger-box {
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;

  .danger-box-logo {
    max-height: 35px;
  }

  .danger-box-title {
    font-size: 11px;
    font-weight: 500;
    text-align: center;
    color: #fff;
  }

  .danger-box-subtitle {
    font-size: 9px;
    opacity: 0.5;
    text-align: center;
    color: #fff;
  }
}
