@use "sass:color";
@use "sass:map";
@import "./stylekit";

$primary: $sml-primary;
$secondary: $sml-secondary;
$info: #86b6ff;
$component-active-bg: $sml-primary;
$border-radius: 0.3rem;
$border-radius-lg: 0.4rem;
$border-radius-sm: 0.25rem;
$font-family-base: "Rubik", "Arial", sans-serif;
$modal-backdrop-opacity: 0.8;
$body-color: $sml-color;
$prefix: "bs-sml-";

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";

// Bootstrap
$custom-colors: (
  "light": $sml-body-bg,
  "accent": $sml-accent,
  "black": black,
  "danger": $sml-danger,
  "danger-opacity": $sml-danger-opacity,
);
$theme-colors: map.merge($theme-colors, $custom-colors);
$pre-color: $body-color;
$card-color: $sml-body-bg;
$card-bg: color.adjust($sml-color, $lightness: 5%);
$card-cap-color: $sml-body-bg;
$card-cap-bg: color.adjust($sml-color, $lightness: 10%);
$pagination-color: $sml-color;
$pagination-border-color: $sml-inactive-icon;
$pagination-hover-border-color: $pagination-border-color;
$pagination-disabled-bg: $sml-body-bg;
$pagination-disabled-color: $pagination-color;
$pagination-disabled-border-color: $sml-inactive-icon;
$breadcrumb-active-color: $sml-body-bg;
$table-dark-border-color: color.change($sml-color, $alpha: 0.3);
$table-border-color: color.change($sml-color, $alpha: 0.3);
$input-bg: color.adjust($sml-body-bg, $lightness: 10%);
$input-disabled-bg: color.adjust($sml-body-bg, $lightness: 3%);
$input-color: $sml-color;
$input-border-color: color.adjust($sml-body-bg, $lightness: -20%);
$pre-color: $body-color;
$input-group-addon-bg: color.adjust(rgba(#fff, 0.56), $lightness: 10%);
$input-group-addon-color: color.adjust($sml-active-icon, $lightness: -80%);
$input-placeholder-color: #b7b7b7;
$box-shadow: 4px 4px 30px rgb(210 210 210 / 25%);
$body-bg: $sml-body-bg;
$btn-font-weight: 500;

.btn.btn-sm {
  font-weight: normal;
}

.btn.btn-outline-danger {
  color: $danger;
}

@import "bootstrap/scss/maps"; /* stylelint-disable-line no-invalid-position-at-import-rule, no-duplicate-at-import-rules  */
@import "bootstrap/scss/mixins"; /* stylelint-disable-line no-invalid-position-at-import-rule, no-duplicate-at-import-rules  */
@import "bootstrap/scss/utilities"; /* stylelint-disable-line no-invalid-position-at-import-rule, no-duplicate-at-import-rules  */
@import "bootstrap/scss/root"; /* stylelint-disable-line no-invalid-position-at-import-rule, no-duplicate-at-import-rules  */
@import "bootstrap/scss/bootstrap"; /* stylelint-disable-line no-invalid-position-at-import-rule, no-duplicate-at-import-rules  */
