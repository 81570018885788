@use "sass:color";
@import "src/assets/styles/fym/stylekit";

.smart-links-social-links {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  width: 100%;

  .smart-links-social-link {
    margin: 0.35rem 0.7rem;
    width: 3.1rem;
    height: 3.1rem;
    text-decoration: none;
    font-size: 1.3rem;
    color: $body-color-dark;
    background: $body-bg-dark;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
