@import "./stylekit";

.user-avatar-border {
  border-width: 2px;
  border-style: solid;
  display: inline-flex;

  .user-avatar-icon {
    opacity: 0.8;
  }
}

.user-avatar-border-danger {
  border-color: $sml-danger;
}

.user-avatar-border-white {
  border-color: #fff;
}
