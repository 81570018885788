@import "./stylekit";

.confirmation-modal-container {
  .modal-content {
    border-radius: 14px;

    .confirmation-modal-content {
      background: $white;
      border-radius: 12px;
      color: $sml-color;

      .btn {
        border-radius: 30px;
        padding: 15px 30px;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
  }
}
