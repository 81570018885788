@import "src/assets/styles/fym/stylekit";

.widget-list-item-content {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  border-radius: 12px;
  border-width: 1px;
  border-style: solid;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-color: $sml-border-color;
  background: $sml-body-bg;

  .widget-list-item-text-container {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 1.8rem;
    font-weight: 400;
    overflow-y: hidden;
    height: 100%;

    .widget-list-item-svg {
      color: #ffecb5;
    }

    .widget-list-item-title {
      font-size: 1.14rem;
    }

    .widget-list-item-normal {
      font-size: 1rem;
    }

    .widget-list-item-small {
      font-size: 0.71rem;
    }
  }
}

.widget-list-item-edit-container {
  position: absolute;
  right: 8px;
  bottom: 8px;

  svg {
    color: $fym-primary;
  }
}

.sc-widget-cover-box-container {
  display: flex;
  border-radius: 12px;
  overflow: hidden;
  width: 100%;
}

.widget-type-icon {
  width: 40px;
  height: 40px;
  background-color: #f8e4ea;
}
