@use "sass:color";
@use "sass:math";
@import "./mainFont";
@import "./bootstrap";
@import "./animations";
@import "./pricing";
@import "sml-button";
@import "./settings";
@import "./userAvatar";
@import "./confirmationModal";
@import "./itemSeparator";
@import "./checkIcon";
@import "./boxes";
@import "./embedPlayer";
@import "./modals";
@import "./forms";
@import "./smartLinks";
@import "./smartLinksNavbar";
@import "./downloadButtons";

.btn.btn-secondary {
  @include button-variant($secondary, $secondary, $body-bg);
}

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($body-color, $body-bg, $value, color.change($value, $alpha: 0.2), $body-bg);

    border-color: color.change($value, $alpha: 0.2);
  }
}

.btn-outline-secondary {
  @include button-outline-variant($body-color, $body-bg, $secondary, $secondary);
}

@each $color, $value in $theme-colors {
  .btn-shadow-#{$color} {
    &:hover {
      transform: translateY(-4px);
    }

    transition: transform 0.3s;
    border: 0;
    box-shadow: 4px 4px 40px #47081b33;
    background: linear-gradient(180deg, color.adjust($value, $whiteness: +15%, $blackness: -15%) 0%, $value 100%);
  }
}

.btn-xl {
  @include button-size("2rem", "2.5rem", $btn-font-size-lg, $btn-border-radius-lg);
}

@each $gap in (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20) {
  .gap-#{$gap} {
    gap: #{$gap}px;
  }
}

@each $opacity in (1, 2, 3, 4, 5, 6, 7, 8, 9) {
  .opacity-#{$opacity} {
    opacity: math.percentage(math.div($opacity, 10));
  }
}

@each $line in (1, 2, 3, 4, 5) {
  .text-#{$line}-line {
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: anywhere;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
    display: -webkit-box;
  }
}

html {
  height: 100%;
  font-size: 12px;
}

@media screen and (width >= 320px) {
  html {
    font-size: calc(12px + 2 * ((100vw - 320px) / 680));
  }
}

@media screen and (width >= 1000px) {
  html {
    font-size: 14px;
  }
}

body {
  height: 100%;
  display: flex;
  flex-direction: column;
}

#root {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #fff;
}

#root-bg {
  z-index: 1;
  position: fixed;
  inset: 0;
  background: #fff no-repeat no-repeat center center;
  background-size: cover;

  @include media-breakpoint-up("sm") {
    background-color: transparent;
    background-image: url("../../images/smartLinks/waves-bg.svg");
  }
}

.ui-text {
  user-select: none;
}

.main-padding {
  padding: $vertical-padding $horizontal-padding !important;

  @include media-breakpoint-down("md") {
    padding: $vertical-md-padding $horizontal-md-padding !important;
  }
}

.main-x-padding {
  padding-left: $horizontal-padding !important;
  padding-right: $horizontal-padding !important;
}

.main-y-padding {
  padding-top: $vertical-padding !important;
  padding-bottom: $vertical-padding !important;
}

.main-t-padding {
  padding-top: $vertical-padding !important;
}

.text-big {
  font-size: 1.25rem;
}

.text-small {
  font-size: 0.75rem;
}

.font-weight-500 {
  font-weight: 500;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-auto {
  cursor: auto !important;
}

.back-btn-container {
  height: 38px;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.d-contents {
  display: contents;
}

.home-messages-container {
  $w: 343px;

  height: 66px;
  position: absolute;
  left: 50%;
  top: 4px;
  margin-left: math.div(-$w, 2);
  width: $w;

  .home-messages {
    text-align: left;
    overflow: hidden;
    position: relative;
    border-radius: 12px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 8px;

    .home-messages-title {
      font-size: 10px;
      font-weight: bold;
      text-transform: uppercase;
      color: $body-color-dark;
    }

    .home-messages-subtitle {
      font-size: 12px;
      color: $body-color-dark;
    }

    .home-messages-image {
      position: absolute;
      top: 12px;
      right: 12px;
      height: 16px;
      width: auto;
    }

    div {
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.opacity-56 {
  opacity: 0.56;
}

.clickable-card {
  transition: 0.3s;
  border-radius: 12px;

  &:hover {
    opacity: 0.5;
  }

  &:active {
    opacity: 0.2;
  }
}

.sml-gradient-background {
  background: $sml-gradient;
}

.options-button-container {
  right: 0;
  position: absolute;
}

.disable-mouse-events {
  pointer-events: none;
}

#side-bar {
  background-color: rgba(#121212, 0.12);
}

.active-icon {
  color: $active-icon-light;
}

.inactive-icon {
  color: $inactive-icon-light;
}

.opacity-bg {
  background-color: $opacity-bg-light;

  &.opacity-bg-darker {
    background-color: color.change($body-color-dark, $alpha: 0.2);
  }
}

.active-icon-dark {
  color: $active-icon-dark;
}

.alert-container {
  bottom: 10px;
  right: 10px;
  z-index: 1000;
  min-width: 200px;
}

.create-smartlink {
  background-position: bottom right;
  background-repeat: no-repeat;
  background-color: white;
  display: flex;
  flex-direction: row;
  place-content: center;
  box-shadow: $box-shadow;
  margin-top: 1rem;
  border-radius: 8px;
  padding: 20px;

  &.create-custom-smartlink-bg {
    background-image: url("../../images/smartLinks/create-custom-smartlink-bg.svg");
  }

  &.create-release-smartlink-bg {
    background-image: url("../../images/smartLinks/create-release-smartlink-bg.svg");
  }

  &.create-playlist-smartlink-bg {
    background-image: url("../../images/smartLinks/create-playlist-smartlink-bg.svg");
  }
}
