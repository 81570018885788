@use "sass:color";

// SML
$sml-body-bg: #fff;
$sml-bg-light: #fafafa;
$sml-color: #121212;
$sml-opacity-bg: rgba(#121212, 0.06);
$sml-active-icon: rgba(#121212, 0.56);
$sml-inactive-icon: rgba(#121212, 0.16);
$sml-opacity-body-bg: rgba(#fff, 0.5);
$sml-border-color: #eaeaea;
$sml-dark-bg: #606062;

// Common
$sml-primary: #6417fb;
$sml-secondary: #de376a;
$sml-accent: #a428af;
$sml-danger: #de376a;
$sml-danger-opacity: color.change($sml-danger, $alpha:0.4);
$sml-gradient: linear-gradient(90deg, $sml-primary 0%, $sml-secondary 100%);
$sml-gradient-y: linear-gradient(180deg, $sml-primary 0%, $sml-secondary 100%);
$sml-gradient-radial: radial-gradient(circle at 50% 100%, $sml-secondary 5%, $sml-primary 80%);
$sml-blue-gradient: linear-gradient(135deg, #0086e8 0%, #00bbf5 100%);
$sml-music-carousel-gradient-dark: linear-gradient(20deg, rgb(255 255 255 / 0%) 0%, rgb(255 255 255 / 12%) 100%);
$sml-black-gradient: linear-gradient(-29deg, rgb(255 255 255 / 0%) 13%, rgb(255 255 255 / 4%) 100%);
$sml-black2-gradient: linear-gradient(0deg, rgb(255 255 255 / 0%) 5%, rgb(255 255 255 / 4%) 100%);
$sml-white-gradient: linear-gradient(-25deg, rgb(255 255 255 / 35%) 5%, #fff 100%);
$sml-white2-gradient: linear-gradient(0deg, #eee 5%, #fff 100%);
$sml-darker-gray-gray-gradient: linear-gradient(270deg, #363636 0%, #494949 100%);

// Helpers
$top-bar-height: 64px;
$side-bar-width: 64px;
$vertical-padding: 64px;
$horizontal-padding: 40px;
