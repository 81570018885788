@import "./stylekit";

.license-status-box-inactive-status-bar {
  background: rgb(25 25 25 / 20%);
}

.premium-plans-container {
  display: flex;
  margin-bottom: 20px;

  @include media-breakpoint-down("md") {
    flex-direction: column;
  }
}

.payment-plan-box {
  &.payment-plan-box-disabled {
    opacity: 0.5;
  }

  margin: 10px;
  flex: 1;
  display: flex;
  border-radius: 12px;
  overflow: hidden;
  justify-content: center;
  flex-direction: column;

  .payment-plan-box-highlighted-text-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;

    span {
      display: block;
      color: white;
      font-size: 10px;
      font-weight: 700;
      text-align: center;
    }
  }

  .payment-plan-box-text-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px 6px;

    .payment-plan-box-title {
      font-weight: 400;
    }

    .payment-plan-box-price {
      font-weight: bold;
      font-size: 30px;
    }

    .payment-plan-box-description {
      font-weight: 100;
    }
  }
}

.license-status-box {
  border-radius: 12px;
  overflow: hidden;
  margin: 20px auto 30px;
  max-width: 700px;

  .license-status-box-text-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 15px 30px;
  }

  .license-status-box-status-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;

    span {
      display: block;
      color: white;
      font-size: 14px;
      font-weight: 700;
      text-align: center;
    }
  }
}

.upsell-title {
  font-weight: 500;
  font-size: 24px;
}

.upsell-subtitle {
  font-weight: 400;
  font-size: 20px;
}

.upsell-main-bg {
  position: absolute;
  left: 0;
  right: 0;
  top: -14%;
  z-index: 1000;
  overflow: hidden;
  margin-right: -2.85rem;
  margin-left: -2.85rem;

  @include media-breakpoint-down("lg") {
    top: 0;
  }
}

.upsell-box {
  border-radius: 22px;
  background-color: #fff;
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-align: center;
  padding: 2rem;
  box-shadow: 8px 8px 40px rgb(132 132 132 / 25%);
  z-index: 9000;

  .upsell-icon {
    color: $sml-secondary;
  }

  .waves {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .upsell-box-title {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    z-index: 70;
    position: relative;
    flex: 1;
  }

  .upsell-box-subtitle {
    z-index: 60;
    position: relative;
  }
}

.feature-box {
  border-radius: 12px;
  padding: 30px 20px;
  margin: 5px;
  display: flex;
  align-items: center;
  max-width: 450px;
  position: relative;

  .feature-box-title {
    font-weight: 500;
    font-size: 24px;
    margin-bottom: 5px;
    display: block;
  }

  .feature-box-premium-label-container {
    position: absolute;
    top: 8px;
    right: 8px;
    display: flex;
    flex-direction: row;

    .feature-box-premium-label {
      border-radius: 5px;
      background: $sml-primary;
      padding: 0 5px;
      margin-left: 5px;
      line-height: 10px;

      span {
        color: white;
        font-size: 9px;
        font-weight: 500;
        margin: 3px 0;
        display: inline-block;
      }
    }
  }
}
