@use "sass:color";
@import "./stylekit";

.download-buttons {
  background: $sml-gradient;
  padding: 30px;
  border-radius: 3px;

  a {
    color: $sml-bg-light;
  }

  .btn.download-button {
    background-position: 10px center;
    background-repeat: no-repeat;
    padding-left: 50px;
    padding-top: 0.775rem;
    padding-bottom: 0.775rem;
    white-space: nowrap;
    border-color: #a6a6a6;
    background-color: $sml-color;
    color: $sml-bg-light;
    width: 200px;

    &:hover {
      color: $sml-bg-light;
      background-color: color.adjust($sml-color, $lightness: 5%);
      opacity: 0.8;
    }

    &.macos-download {
      background-position: 16px 36%;
      background-image: url("../../images/download/download-macos.svg");
    }

    &.windows-download {
      background-position-x: 14px;
      background-image: url("../../images/download/download-windows.svg");
    }

    &.linux-download {
      background-position-x: 12px;
      background-image: url("../../images/download/download-linux.svg");
    }

    &.ios-download {
      background-image: url("../../images/download/download-ios.svg");
    }

    &.android-download {
      background-image: url("../../images/download/download-android.svg");
    }
  }

  .btn.download-mobile-button {
    width: 140px;
    background-size: 80%;

    @include media-breakpoint-up("lg") {
      width: 160px;
    }
  }

  .download-native-store-image {
    width: 160px;
    margin-bottom: 12px;

    &:hover {
      opacity: 0.8;
    }
  }
}
