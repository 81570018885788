@import "./stylekit";

.settings-title {
  font-weight: 100;
  opacity: 0.4;
  padding: 0 40px;
}

.settings-items-container {
  margin-top: 32px;
  margin-bottom: 48px;

  .settings-item-container {
    height: 80px;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;

    .settings-item-color-line {
      width: 4px;
      height: 30px;
      margin-right: 12px;
      border-radius: 4px;
    }

    .settings-item-text {
      font-size: 1.25rem;
    }
  }
}
