@use "sass:color";
@import "./stylekit";

.sml-navbar {
  @include media-breakpoint-up("sm") {
    .container {
      padding-left: 0;
      padding-right: 0;
    }
  }

  background-color: $sml-opacity-body-bg;
  backdrop-filter: blur(8px);

  .nav-item {
    a {
      color: $sml-color;
    }
  }
}
