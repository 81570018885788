@use "sass:color";
@import "./stylekit";

.sml-button-tooltip-error {
  .tooltip-inner {
    background: $sml-danger;
  }

  .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
    border-top-color: $sml-danger;
  }

  .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
    border-bottom-color: $sml-danger;
  }

  .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
    border-left-color: $sml-danger;
  }

  .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
    border-right-color: $sml-danger;
  }
}
