@use "sass:color";
@import "./stylekit";

.sml-home-link {
  span {
    color: $sml-primary;
    font-weight: 400;
    font-size: 1.14rem;
  }

  svg {
    color: $sml-primary;
  }
}

.sml-box {
  min-height: 160px;
  border-radius: 8px;
  position: relative;
  border: 1px solid color.change($sml-border-color, $alpha: 0.4);
}

.sml-artist-page-box {
  background: linear-gradient(180deg, #498fff 0%, #6417fb 100%);
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
}

.sml-creation-box {
  background: linear-gradient(180deg, #ff5e8f 0%, #de376a 100%);
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
}

.sml-stats-box-content {
  z-index: 1;
}

.sml-stats-box-waves {
  flex: 1;

  .sml-stats-box-wave {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
  }
}

.sml-list-item-box-title-container {
  background-color: #fff;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  .sml-list-item-box-title {
    color: $sml-color;
    font-weight: normal;
  }
}

.sml-list-item-box-badges-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.sml-list-item-box-image-container {
  flex: 1;
  background-color: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-size: cover;
  background-position: center center;
}
