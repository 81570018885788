@use "sass:color";
@import "bootstrap/scss/mixins/breakpoints";
@import "./stylekit";

.sml-set-up-box {
  position: relative;
  background-color: #fff;

  @include media-breakpoint-up("sm") {
    box-shadow: 0.6rem 0.6rem 3rem rgb(132 132 132 / 25%);
    border-radius: 10px;
    max-width: 600px;
  }

  .sml-set-up-logo {
    left: -45px;
    top: 35px;
    height: 75px;
    width: 98px;
  }

  .sml-set-up-text {
    font-size: 1.2rem;
    color: #8e8e8e;
  }

  .sml-sing-up-link {
    color: $sml-primary;
  }
}
