@import "./stylekit";

$swing-deg: 2deg;

@keyframes swinging {
  0% { transform: rotate($swing-deg); }
  50% { transform: rotate($swing-deg * -1); }
  100% { transform: rotate($swing-deg); }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-20px);
  }

  60% {
    transform: translateY(-10px);
  }
}
