@font-face {
  font-family: Rubik;
  src: local("Rubik Bold"), local("Rubik-Bold"), url("../../fonts/Rubik-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: Rubik;
  src: local("Rubik Regular"), local("Rubik-Regular"), url("../../fonts/Rubik-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Rubik;
  src: local("Rubik Bold Italic"), local("Rubik-BoldItalic"), url("../../fonts/Rubik-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: Rubik;
  src: local("Rubik Light"), local("Rubik-Light"), url("../../fonts/Rubik-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Rubik;
  src: local("Rubik Black"), local("Rubik-Black"), url("../../fonts/Rubik-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: Rubik;
  src: local("Rubik Black Italic"), local("Rubik-BlackItalic"), url("../../fonts/Rubik-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: Rubik;
  src: local("Rubik Light Italic"), local("Rubik-LightItalic"), url("../../fonts/Rubik-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: Rubik;
  src: local("Rubik Italic"), local("Rubik-Italic"), url("../../fonts/Rubik-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: Rubik;
  src: local("Rubik Medium"), local("Rubik-Medium"), url("../../fonts/Rubik-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Rubik;
  src:
    local("Rubik Medium Italic"),
    local("Rubik-MediumItalic"),
    url("../../fonts/Rubik-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}
