@use "sass:color";
@use "sass:math";
@import "setUp";
@import "stylekit";
@import "smartLinksList";
@import "smartLinksNavbar";
@import "smartLinkPreview";
@import "smartLinksEdition";
@import "Widgets/arrows";
@import "Widgets/listItem";
@import "Widgets/socialLinks";

.sml-setup-container {
  z-index: 2;
  box-shadow: 8px 8px 40px rgb(132 132 132 / 25%) !important;
  border-radius: 10px;
  flex: 1;
  height: 100%;
}

.sml-container {
  z-index: 2;
  box-shadow: 8px 8px 40px rgb(132 132 132 / 25%) !important;
  background-color: $sml-body-bg;

  &.sml-container-light {
    background-color: $sml-bg-light;
  }

  border-radius: 10px 10px 0 0;
  flex: 1;
  height: 100%;
}

.sml-container-header {
  background-color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-top: 1rem;
  padding-bottom: 1rem;

  h3 {
    font-size: 1.3rem;
    min-height: 40px;
    line-height: 40px;
  }
}

.sml-settings {
  background-color: $sml-body-bg;
}

.smart-link-manage-url-container {
  border: 2px solid #dadada;
  border-radius: 16px;

  .smart-link-manage-url-title {
    position: absolute;
    top: -11px;
    left: 43px;
    background-color: $sml-body-bg;
  }
}

.sml-wizard-logo-services {
  left: 10px;
  right: 10px;
  top: -28px;
  position: absolute;
  background-color: $sml-body-bg;
}

.smart-link-manage-share-url {
  border-radius: 8px;
  background-color: $sml-bg-light;
}

.smart-link-info-box {
  display: flex;
  flex-direction: row;
  padding: 1.28rem 1.71rem;
  border-radius: 8px;
  justify-content: center;
  align-self: center;
  align-items: center;

  span {
    opacity: 0.5;
  }
}

.sml-draft-badge-container {
  font-size: 0.8rem;
  display: flex;
  align-items: center;

  .sml-draft-badge {
    background-color: #f0f6ff !important;
    color: #86b6ff;

    &.sml-draft-badge-lg {
      border-radius: 10px;
      padding: 0.71rem;
      font-weight: 600;
      font-size: 1rem;
    }
  }
}

.sml-details-right-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-left: 1px solid #d1d1d1;

  .sml-details-publish-button-container {
    border-top: 1px solid #d1d1d1;
    display: flex;
    justify-content: center;
    height: 100%;
  }
}

.sml-details-widgets-button-container {
  background-color: #fff;
  margin-bottom: 0.57rem;
  border-radius: 8px;
  display: flex;
  align-items: center;
  color: $body-color-light;
  padding: 1rem;

  &:hover {
    background-color: $sml-secondary;
    color: #fff;
  }
}

.sml-creation-button-container {
  display: flex;
  flex-direction: column;
}

.player-widget-icon {
  background-color: $sml-body-bg;
}

.sml-search-bar {
  position: relative;

  .search-icon {
    position: absolute;
    top: 12px;
    left: 24px;
    color: $sml-border-color;
  }

  .search-input {
    background: #fff;
    padding-left: 36px;
    padding-top: 8px;
    padding-bottom: 8px;

    &:focus {
      box-shadow: 4px 4px 15px rgb(116 116 116 / 25%);
    }

    border-radius: 8px;
  }
}
