@import "src/assets/styles/fym/stylekit";

@include color-mode(light) {
  .arrows-circle {
    background-color: #575757;

    svg {
      color: #fff;
    }
  }
}

@include color-mode(dark) {
  .arrows-circle {
    background-color: #dfdfdf;

    svg {
      color: #000;
    }
  }
}

.arrows-circle {
  width: 2.28rem;
  height: 2.28rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &.arrows-circle-disabled {
    opacity: 0.3;
  }
}
