@use "sass:color";
@import "./stylekit";

.fym-form-help {
  max-width: 600px;
  font-size: 0.9rem;
  padding-bottom: 10px;
}

.fym-form-label {
  font-weight: 500;
}

.fym-form-dropzone-container {
  display: flex;
  flex-direction: row;
  flex: 1 1 0;
  min-height: 150px;

  .fym-form-dropzone {
    flex: 1;
    border-width: 1px;
    border-style: dashed;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    place-content: center center;
    align-items: center;
    height: inherit;
    margin-right: 10px;
  }

  .fym-form-dropzone-preview {
    flex: 1;
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    place-content: center center;
    align-items: center;
    max-width: 30%;

    .fym-form-hovered-img {
      position: relative;
      width: 100%;
      height: 100%;

      svg {
        display: none;
      }

      &::after {
        content: "";
        display: block;
        padding-bottom: 100%;
      }

      .fym-form-hovered-img-img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        background-size: cover;
        background-repeat: no-repeat;
      }

      &:hover:not(.disabled) {
        .fym-form-hovered-img-img {
          opacity: 0.3;
          transition: 0.5s ease;
        }

        svg {
          display: inline-block;
        }

        .fym-form-deletion-icon-container {
          padding: 15px;
          background-color: $sml-secondary;
          position: absolute;
          inset: 0;
          margin: auto;
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .fym-form-readonly-placeholder-icon {
      color: #8e8e8e;
    }
  }
}

.fym-form-readonly-input {
  padding: 6px 12px;
  background-color: color.change($sml-secondary, $alpha: 0.1);
  border-radius: 4px;
  border: 1px solid color.change($sml-secondary, $alpha: 0);
}

.sml-onboarding-form-social-links-container {
  overflow-y: auto;
  max-height: 300px;
}

.sml-account-form-social-links-container {
  overflow-y: auto;
  max-height: 250px;
}

.form-theme-selection-preview-container {
  display: flex;
  flex-direction: row;
  overflow: auto hidden;
  padding-top: 10px;
  padding-bottom: 10px;

  .form-theme-selection-preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    border: 3px solid $sml-body-bg;
    border-radius: 8px;
    padding: 8px;

    &.form-theme-selection-preview-checked {
      border-color: $input-btn-focus-color;
    }

    img {
      max-height: 240px;
      border-radius: 4px;
    }

    div {
      font-weight: 500;
      padding-top: 6px;
    }
  }
}

$sml-form-input-blue-color: #7eb2ff;
$sml-form-input-blue-bg: #f0f6ff;

.fym-form-input-blue-container {
  background-color: $sml-form-input-blue-bg;
  border-color: $sml-form-input-blue-color;
  color: $sml-form-input-blue-color;

  small {
    color: $sml-form-input-blue-color;
  }
}
