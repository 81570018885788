@import "./stylekit";

.embed-modal-container {
  min-width: 700px;

  .modal-content {
    border-radius: 10px;
    border: 0;

    .embed-modal-close {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: $zindex-modal + 1;

      .btn {
        color: $sml-color;
      }
    }

    .embed-modal {
      display: flex;
      background: #fff;
      border-radius: 8px;
      overflow: hidden;

      .embed-modal-player-container {
        display: contents;
      }

      h3 {
        color: #000;
        padding-bottom: 20px;
      }
    }
  }
}
