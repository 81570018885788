@use "sass:color";
@import "./stylekit";

.check-icon-not-checked {
  width: 24px;
  height: 24px;
  border-radius: 50px;
  border-width: 1px;
  border-style: solid;
  border-color: color.change($sml-color, $alpha: 0.32);
}
