@import "./stylekit";

.sml-form-modal-container {
  width: 100%;
  background-color: $sml-body-bg;

  .modal-header {
    padding: 1.2rem 2.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $sml-opacity-bg;

    .btn {
      color: $sml-color;
    }

    .modal-title {
      user-select: none;
    }
  }

  .modal-body {
    padding: 1.5rem 2.5rem;
  }

  .modal-footer {
    padding: 0.85rem 2.5rem;
    justify-content: space-between;
    border-top: 1px solid $sml-opacity-bg;
  }
}

.sml-modal {
  max-width: 600px;

  &.sml-modal-small {
    max-width: 400px;
  }

  .sml-modal-content {
    background: $white;
    border-radius: 10px;
    color: $sml-color;

    .custom-sml-logo-container {
      border-radius: 8px;
      background-color: #fff9fa;
    }

    .release-link-logo-container {
      border-radius: 8px;
      background-color: #f0f6ff;
    }
  }
}

.sml-modal-container {
  .sml-modal-content {
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    padding: 2.85rem;
    margin: 0 auto;
    border-radius: 8px;
    text-align: center;
    position: relative;
    background: #f9f9f9;
  }

  .modal-content {
    border-radius: 10px;
    border: 0;

    .sml-modal-close {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: $zindex-modal + 1;

      .btn {
        color: $sml-body-bg;
      }
    }
  }
}
